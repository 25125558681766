/**
 * 
 * 
 * 
 */


import { Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, Link, ThemeProvider, Typography, createTheme, responsiveFontSizes } from '@mui/material';
import logo from './logo.svg';
import mistercam from './mistercam.svg';
import background from './achtergrond-2.svg';
import commandoCentrum from './mobiel-kantoor-interieur-1.jpeg'; 
import commandoCentrum2 from './mobiel-kantoor-interieur-2.jpeg';
import walkieTalkie from './walkie-talkie.png';
import cam from './cam-2.png';
import React from 'react';

let theme = createTheme({
  palette: {
    primary: {
      main: '#000045',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 10
        },
        outlined: {
          borderRadius: 10
        }
      }
    }
  }
});

theme = responsiveFontSizes(theme);


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{backgroundImage: `url(${background})`, backgroundSize: 'contain', backgroundPositionX: 'center', backgroundPositionY: '50px', backgroundRepeat: 'no-repeat', backgroundBlendMode: 'soft-light'}}>
        <Container>
          <Box component="header" display="flex" alignItems={"left"}>
            <Box 
              component="img"
              src={logo}
              alt="Mister-Cam.eu"
              sx={{maxHeight: '60px', minWidth: {xs: '100%', sm: 300}}}
            />
            <Box flexGrow={1}/>
            <Box sx={{display: {xs: 'none', sm: 'inline-block'}}}>
              <Button variant='outlined' color="primary" component={Link} href="mailto:info@mister-cam.eu">
                Meer Weten?<br />
                info@mister-cam.eu
              </Button>
            </Box>
          </Box>

          <Divider sx={{marginTop: 2, marginBottom: {xs: 1, sm: 6}, borderColor: '#000045'}} />

          <Grid container direction="row-reverse">
            <Grid item xs={12} md={4}>
              <Box component={"img"} src={mistercam} alt={"MisterCam"} sx={{width: '100%', display: { xs: 'none', md: 'block'}}} />
            </Grid>
            <Grid xs />
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{color: 'grey'}}>Voor inzicht en communicatie</Typography>
              <Typography variant="h2" sx={{color: '#000045', fontWeight: 'bold'}}>op uw evenement</Typography>
              <Typography variant='h4' sx={{color: '#000045', lineHeight: 1.8, marginTop: {xs: 1, sm:6}}}>Wij plaatsen tijdelijke camera-, internet-, protofoon- en pinsystemen op evenementen.</Typography>
              <Button 
                variant="contained"
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 4,
                  paddingRight: 4,
                  marginTop: {
                    xs: 1,
                    sm: 2,
                  },
                  width: {
                    xs: '100%',
                    sm: 'unset'
                  }
                }}
                component={Link}
                href="mailto:info@mister-cam.eu"
              >
                Neem Contact Op
              </Button>
            </Grid>
          </Grid>

          <Divider sx={{marginTop: 6, marginBottom: {xs: 2, sm:6} }} />

          <Box sx={{textAlign: 'center', padding: {xs: 1, sm: 6}}}>
            <Typography variant='h5' sx={{marginBottom: 3, color: '#000045', fontWeight: 'bold'}}>
              Wij zijn Mister Cam
            </Typography>
            <Typography paragraph variant="h6">
              Wij zijn een toonaangevende aanbieder van techniek en diensten voor de evenementen (veiligheids) sector. Wij beschikken over een Mobiele Commando Unit (MCU) met daarin 6 ruime werkplekken. Centralisten voor veiligheid, verkeer, medisch en andere zaken omtrent uw evenement kunnen vanuit de MCU werken.
            </Typography>
            <Typography paragraph variant="h6">
              U kunt bij ons terecht voor cameratoezicht, portofoons en internet verbindingen voor bijvoorbeeld pin-automaten.
            </Typography>
            <Typography paragraph variant="h6">
              U krijgt door onze techniek inzicht en communicatie op uw evenement.
            </Typography>
            <Typography paragraph variant="h6">
              Meer weten? We vertellen u graag wat de mogelijkheden zijn.          
            </Typography>
          </Box>

          <Divider sx={{marginTop: 6, marginBottom: {xs: 2, sm:6} }} />

          <Box sx={{padding: {xs: 1, sm: 6}, textAlign: 'center'}}>
            <Typography variant='h5' sx={{marginBottom: 3, color: '#000045', fontWeight: 'bold'}}>
              Onze Diensten
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{height: 244, borderRadius: 6}}>
                  <CardMedia 
                    src={commandoCentrum2}
                    component="img"
                    height="140"
                    alt="Mobiel Commando Centrum"
                  />
                  <CardContent>
                    <Typography variant="h6">Mobiel Commandocentrum</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{height: 244, borderRadius: 6}}>
                  <CardMedia 
                    src={cam}
                    component="img"
                    height="140"
                    alt="Mobiel Commando Centrum"
                  />
                  <CardContent>
                    <Typography variant="h6">Tijdelijke Beveiligingscamera's</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{height: 244, borderRadius: 6}}>
                  <CardMedia 
                    src={walkieTalkie}
                    component="img"
                    height="140"
                    alt="Mobiel Commando Centrum"
                  />
                  <CardContent>
                    <Typography variant="h6">Portofoons & Communicatie</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card sx={{height: 244, borderRadius: 6}}>
                  <CardMedia 
                    src={commandoCentrum}
                    component="img"
                    height="140"
                    alt="Mobiel Commando Centrum"
                  />
                  <CardContent>
                    <Typography variant="h6">Tijdelijk Internet & Betalingsystemen</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          
          <Divider sx={{marginTop: 6, marginBottom: {xs: 2, sm:6} }} />

          <Box sx={{borderRadius: 10, backgroundColor: 'primary.main', color: 'primary.contrastText', padding: {xs: 4, sm: 8}}}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography sx={{color: 'primary.contrastText'}}>
                    MisterCam <br />
                    Onderdeel van ReRent B.V.<br />
                    Handelskade West 15<br />
                    7547 AW Enschede<br />
                    <Link sx={{color: 'primary.contrastText'}} href="tel:0031880537100">T. 088-053 71 00</Link><br />
                    <Link sx={{color: 'primary.contrastText'}} href="mailto:info@mister-cam.eu">E. info@mister-cam.nl</Link><br />
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} sx={{textAlign: 'right'}}>
                <Typography variant='h5'>Wij vertellen u graag hoe we u kunnen helpen</Typography>
                <Typography variant='h5'>Neem contact met ons op.</Typography>
                <Button component={Link} href="tel:0031880537100" variant="outlined" color="inherit" sx={{padding: 2, margin: 2, fontWeight: 'bold'}}>Bel ons: 088 - 053 71 00</Button>
                <Button component={Link} href="mailto:info@mister-cam.eu" variant="outlined" color="inherit" sx={{padding: 2, margin: 2, fontWeight: 'bold'}}>Mail ons: info@mister-cam.eu</Button>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{marginTop: 6, marginBottom: {xs: 2, sm:6} }} />

        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
